<template>
  <b-modal
    id="EditClientModal"
    title="Изменить клента"
    size="lg"
    hide-footer
    no-enforce-focus
    :no-close-on-backdrop="true"
    @show="getDivision()"
    @shown="getClientById()"
  >
    <b-row class="d-flex justify-content-center flex-column">
      <b-col cols="12" class="p-1 m-1 border-color">
        <h5><strong>Личная информация</strong></h5>
        <b-row>
          <b-col cols="4" class="pr-1 border-right">
            <label for="">Подразделение <i class="required">*</i></label>
            <v-select
              v-model="client.division_id"
              :reduce="(option) => option.id"
              placeholder="Подразделение"
              label="name"
              :options="divisions"
              class="select-size-md"
            />
            <label for="">Фамилия <i class="required">*</i></label>
            <b-input v-model="client.last_name" placeholder="Фамилия" />
            <label for="">Имя <i class="required">*</i></label>
            <b-input v-model="client.first_name" placeholder="Имя" />
          </b-col>
          <b-col cols="4" class="pl-1 border-right">
            <label for="">Отчество</label>
            <b-input v-model="client.patronymic" placeholder="Отчество" />
            <label for="example-input"
              >Дата рождения <i class="required">*</i></label
            >
            <b-input-group class="">
              <b-form-input
                id="example-input"
                v-model="client.birth_date"
                type="date"
                autocomplete="off"
                show-decade-nav
              />
            </b-input-group>
            <label for="" class="mb-1">Пол <i class="required">*</i></label>
            <div class="d-flex justify-content-between adaptiv">
              <b-form-radio
                name="some-radios"
                v-for="gender in genders"
                :key="gender.id"
                :value="gender.id"
                v-model="client.gender"
              >
                {{ gender.name }}
              </b-form-radio>
            </div>
          </b-col>
          <b-col>
            <h5 class="mb-3">
              <strong>Контакная информация</strong>
            </h5>
            <label for="">Телефон <i class="required">*</i></label>
            <b-input v-model="client.phone" placeholder="992927777777" />
            <label for="">Email <i class="required">*</i></label>
            <b-input
              type="email"
              v-model="client.email"
              placeholder="user@mail.com"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="border-color p-1 m-1">
        <div class="d-flex justify-content-between">
          <h5><strong>Удостоверение личности</strong></h5>
        </div>
        <b-row>
          <b-col cols="12" class="">
            <label for="">Номер удостоверения личности </label>
            <b-input
              v-model="client.id_num_passport"
              type="text"
              placeholder="(1 буква 8 цифр)"
              v-mask="'A########'"
            />
          </b-col>
        </b-row>
        <label for="" class="mt-2">Дополнительная информация</label>
        <b-form-textarea
          v-model="client.dop_info"
          placeholder="Введите текст"
        ></b-form-textarea>
        <b-form-checkbox
          v-model="client.is_active"
          class="custom-control-primary mt-2"
        >
          {{ client.is_active ? "Не блокирован" : "Блокирован" }}
        </b-form-checkbox>
        <b-col class="mt-2"> </b-col>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col class="d-flex justify-content-end">
        <b-button
          :disabled="submitButtonDisabled"
          @click="editClient()"
          variant="primary"
          >Изменить
        </b-button>
        <b-button @click="close()" class="ml-1" variant="danger"
          >Отмена</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { mask } from "vue-the-mask";
export default {
  components: {
    vSelect,
    ToastificationContent,
  },
  props: ["id"],
  directives: { mask },
  data() {
    return {
      client: {},
      submitButtonDisabled: false,
      genders: [
        { id: 0, name: "Женский" },
        { id: 1, name: "Мужской" },
      ],
      divisions: [],
    };
  },
  methods: {
    getDivision() {
      this.$http
        .get("divisions")
        .then((res) => {
          this.divisions = res.data;
        })
        .catch((err) => {});
    },
    getClientById() {
      this.$http
        .get(`clients/${this.id}/edit`)
        .then((res) => {
          this.client = res.data;
          this.client.is_active == 1 ? (this.client.is_active = true) : false;
          this.client.birth_date == true
            ? this.client.birth_date.substring(
                0,
                this.client.birth_date.indexOf(" ")
              )
            : null;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    close() {
      this.client = {
        division_id: 1,
        first_name: null,
        last_name: null,
        patronymic: null,
        phone: null,
        birth_date: null,
        gender: null,
        email: null,
        id_num_passport: null,
        dop_info: null,
        is_active: 1,
      };
      this.submitButtonDisabled = false;
      this.$bvModal.hide("EditClientModal");
    },
    editClient() {
      this.clientBlock(
        this.client.is_active
          ? `clients/${this.id}/activate`
          : `clients/${this.id}/deactivate`
      );
      // this.client.is_active = this.client.is_active ? 1 : 0;
      // this.submitButtonDisabled = true;
      // this.$http
      //   .patch(`clients/${this.id}`, this.client)
      //   .then((res) => {
      //     this.close();
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: "top-right",
      //       props: {
      //         title: "Успех!",
      //         icon: "CheckIcon",
      //         variant: "success",
      //         text: `Клиент успешно изменен!`,
      //       },
      //     });
      //     this.$emit("refresh");
      //   })
      //   .catch((err) => {
      //     this.$toast({
      //       component: ToastificationContent,
      //       position: "top-right",
      //       props: {
      //         title: "Некорректные данные!",
      //         icon: "XIcon",
      //         variant: "danger",
      //         text: err,
      //       },
      //     });
      //   })
      //   .finally(() => {
      //     this.submitButtonDisabled = false;
      //   });
    },
    clientBlock(url) {
      this.$http
        .put(url)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Клиент успешно изменен!`,
            },
          });
          this.$emit("refresh");
          this.close();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.border-color {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: auto;
}

.required {
  color: red;
}

@media (max-width: 993px) {
  .adaptiv {
    display: block !important;
  }
}
</style>
